@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  src: url('./assets/fonts/Pretendard-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  src: url('./assets/fonts/Pretendard-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src: url('./assets/fonts/Pretendard-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: url('./assets/fonts/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: url('./assets/fonts/Pretendard-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: url('./assets/fonts/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  src: url('./assets/fonts/Pretendard-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  src: url('./assets/fonts/Pretendard-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  src: url('./assets/fonts/Pretendard-Thin.woff2') format('woff2');
}

* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Pretendard;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  font-family: Pretendard;
}

body {
  background-color: #000000;
  overflow-x: hidden;
  margin: 0;
}

main {
  min-height: calc(100vh - 6rem);
}

a {
  color: unset;
  cursor: 'pointer';
  text-decoration: none;
}

.fade-in {
  opacity: 0;
  animation: fade-in;
  -moz-animation: fade-in 1.5s; /* Firefox */
  -webkit-animation: fade-in 1.5s; /* Safari and Chrome */
  -o-animation: fade-in 1.5s; /* Opera */
  animation-fill-mode: forwards;
  animation-delay: 7s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
